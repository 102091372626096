<template>
    <div class="content-page">
        <el-button class="backToTop" type="primary" @click="backTop">↑</el-button>

        <div class="content-nav">
            <el-breadcrumb class="breadcrumb" separator="/">
                <el-breadcrumb-item :to="{ name: 'goods' }">商品管理</el-breadcrumb-item>
                <el-breadcrumb-item>{{infoForm.id ? '编辑商品' : '添加商品'}}</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="operation-nav">
                <el-button type="primary" @click="onSubmitInfo">确定保存</el-button>
                <el-button @click="goBackPage" icon="arrow-left">返回列表</el-button>
            </div>
        </div>
        <div class="content-main">
            <div class="form-table-box">
                <el-form ref="infoForm" :rules="infoRules" :model="infoForm" label-width="120px">
                    
                    <el-form-item label="商品名称" prop="name">
                        <el-input v-model="infoForm.name"></el-input>
                    </el-form-item>

                    <!-- <el-form-item  label="商品分类">
                        <el-select class="el-select-class" v-model="categorys"
                                   placeholder="选择型号分类">
                            <el-option
                                    v-for="item in cateOptions"
                                    :key="item.name"
                                    :label="item.label"
                                    :value="item.name">
                            </el-option>
                        </el-select>
                    </el-form-item> -->

                    <el-form-item label="商品分类" prop="categorys">
                        <el-select  placeholder="请选择分类标签" multiple v-model="categorys">
                            <el-option label="选择所有" value="all"></el-option>
                            <el-option v-for="item in cateOptions" :label="item.name" :value="item.name" :key="item._id"></el-option>
                        </el-select>

                    </el-form-item>

                    <el-form-item label="商品图片" prop="list_pic_url" v-if="!!infoForm.list_pic_url"
                                  class="image-uploader-diy new-height">
                        <img v-if="infoForm.list_pic_url" :src="infoForm.list_pic_url" class="image-show">
                        <el-button class="dele-list-pic" type="primary" @click="delePicList">
                            <i class="fa fa-trash-o"></i>
                        </el-button>
                    </el-form-item>

                    <el-form-item label="商品图片" prop="list_pic_url" v-if="!infoForm.list_pic_url">
                        <el-upload
                                name="file"
                                class="upload-demo"
                                :action="qiniuZone"
                                :on-success="handleUploadListSuccess"
                                :before-upload="getQiniuToken"
                                :on-remove="picUrlRemove"
                                list-type="picture-card"
                                :data="picData"
                        >
                            <el-button size="small" type="primary">点击上传</el-button>
                            <div slot="tip" class="el-upload__tip">图片长宽：500*500 | 只能上传jpg/png文件，且不超过500kb</div>
                        </el-upload>
                    </el-form-item>

                    <el-form-item label="商品轮播图" prop="goods_gallery" >
                        <el-upload
                                name="file"
                                class="upload-demo"
                                :action="qiniuZone"
                                list-type="picture-card"
                                :on-preview="galleryPreview"
                                :on-success="handleUploadGallerySuccess"
                                :on-remove="galleryRemove"
                                :show-file-list="false"
                                :data="picData"
                                :before-upload="galleryBefore"
                                :on-error="hasErrorAct"
                        >
                            <i class="el-icon-plus"></i>
                            <div slot="tip" class="el-upload__tip">图片长宽：待给出 | 只能上传jpg/png文件，且单个不超过500kb</div>
                        </el-upload>

                        <draggable
                            class="list-group"
                            tag="ul"
                            v-model="gallery_urls"
                            v-bind="dragOptions"
                            @start="drag = true"
                            @end="dragend"
                        >
                        <!--<transition-group type="transition" :name="!drag ? 'flip-list' : null">-->
                        <div v-loading="loadingapp" class="img-wrapper divBox" v-for="(item,index) in gallery_urls" >
                            <img :src="item.url" v-if="item.url" width="148" height="148" class="imgSty">
                            <i class="el-icon-delete delIcon" @click="deleImg(item.url,index)"></i>
                            <!--<img class="img-big" :src="imgUrl" v-else width="362" height="272">-->
                        </div>
                        <!--</transition-group>-->
                    </draggable>
                        <!-- <el-button type="primary" size="small" @click="goodsGalleryEdit">编辑顺序</el-button> -->

                        <el-dialog v-model="dialogVisible" size="tiny">
                            <img width="100%" :src="dialogImageUrl" alt="">
                        </el-dialog>
                    </el-form-item>

               
                    <el-form-item label="商品简介" prop="goods_brief">
                        <el-input type="textarea" v-model="infoForm.goods_brief" :rows="3"></el-input>
                        <div class="form-tip"></div>
                    </el-form-item>

                    <el-form-item label="商品重量" prop="goods_weight">
                        <el-input v-model="infoForm.goods_weight"></el-input>
                        <div class="form-tip">单位：kg</div>
                    </el-form-item>

                    <el-form-item label="商品单位" prop="goods_unit">
                        <el-input v-model="infoForm.goods_unit"></el-input>
                        <div class="form-tip">如：盒，件、包、袋</div>
                    </el-form-item>
                    
                    <el-form-item label="库存" prop="goods_number">
                        <el-input type="number" v-model="infoForm.goods_number"></el-input>
                    </el-form-item>
                    
                    <el-form-item label="时长要求" prop="play_times">
                        <el-input type="number" v-model="infoForm.play_times"></el-input>
                        <div class="form-tip">单位：分钟</div>
                    </el-form-item>

                    <el-form-item label="人数要求" prop="player_min_no">
                        <el-input type="number" style="display: none;" v-model="infoForm.player_min_no"></el-input>
                        <el-col :span="2">
                            <el-input type="number" v-model="infoForm.player_min_no"></el-input>
                        </el-col>
                        <el-col :span="1" style="text-align: center;">
                        ~
                        </el-col>
                        <el-col :span="2">
                            <el-input type="number" v-model="infoForm.player_max_no"></el-input>
                        </el-col>
                    </el-form-item>

                    <el-form-item label="年龄要求" prop="player_age">
                        <el-input type="number" v-model="infoForm.player_age"></el-input>
                        <div class="form-tip">单位：岁</div>
                    </el-form-item>

                   
                    <el-form-item label="关键字" prop="keywords">
                  
                        <template v-for="(item,index) in infoForm.keywords"> 
                            <div class='el-button primary el-button--mini is-plain newtag' 
                            @mouseenter="showCloseBtn(index)" @mouseleave="hideCloseBtn(index)">
                            
                                <div contenteditable="true" class="newtag-content" style=" outline: none;" :ref='`newTag${index}`' @blur="checkTag(index)">
                                    {{ item }}
                                </div>
                                <i class="close-i el-icon-circle-close" :ref="`closeI${index}`" @click="removeTag(index)"></i>
                            </div>
                        </template>
                        <i class="el-icon-circle-plus-outline addtag" @click="addTag()"></i>
              
                    </el-form-item>

                    <!-- 图片上传组件辅助-->
                    <el-form-item class="upload_ad">
                        <el-upload
                                name="file"
                                class="avatar-uploader"
                                :action="qiniuZone"
                                list-type="picture-card"
                                :file-list="detail_list"
                                :before-upload="beforeUpload"
                                :on-success="handleUploadDetailSuccess"
                                :on-preview="handlePreview"
                                :data="picData"
                                >
                        </el-upload>
                    </el-form-item>
                    
                    <!-- <el-form-item label="属性设置" class="checkbox-wrap">
                        <el-checkbox-group v-model="infoForm.is_new" class="checkbox-list">
                            <el-checkbox label="新品抢鲜" name="is_new"></el-checkbox>
                        </el-checkbox-group>
                    </el-form-item> -->

                    <!-- <el-form-item label="排序" prop="sort_order">
                        <el-input-number :mini="1" :max="100" v-model="infoForm.sort_order"></el-input-number>
                    </el-form-item> -->
                    <el-form-item label=" ">
                        <el-switch active-text="上架" inactive-text="下架" active-value="1" inactive-value="0"
                                   v-model="infoForm.is_on_sale"></el-switch>
                    </el-form-item>

                    <el-form-item label="特殊卖点" prop="special_feature">
                        <el-input v-model="infoForm.special_feature"></el-input>
                        <div class="form-tip">特殊卖点</div>
                    </el-form-item>

                    <el-form-item label="商品零售价" prop="retail_price">
                        <el-input type="number" v-model="infoForm.retail_price"></el-input>
                        <div class="form-tip">单位：元</div>
                    </el-form-item>

                    <el-form-item label="添加商品型号">
                        <div class="spec-wrap">
                            <el-table :data="specData" stripe style="width: 100%">
                                <el-table-column prop="spec_sn" label="商品型号编号" width="140">
                                    <template scope="scope">
                                        <el-input @blur="checkSkuOnly(scope.$index, scope.row)" size="mini" v-model="scope.row.spec_sn" placeholder="商品型号编号"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="spec_name" label="简称" width="160">
                                    <template scope="scope">
                                        <el-input size="mini" v-model="scope.row.spec_name" placeholder="简称"></el-input>
                                    </template>
                                </el-table-column>
<!--                          
                                <el-table-column prop="cost" label="成本(元)" width="100">
                                    <template scope="scope">
                                        <el-input size="mini" v-model="scope.row.cost" placeholder="成本"></el-input>
                                    </template>
                                </el-table-column> -->
                                <el-table-column prop="retail_price" label="零售(元)" width="100">
                                    <template scope="scope">
                                        <el-input size="mini" v-model="scope.row.retail_price" placeholder="零售"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="spec_weight" label="重量(KG)" width="100">
                                    <template scope="scope">
                                        <el-input size="mini" v-model="scope.row.spec_weight" placeholder="重量"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="spec_number" label="库存" width="100">
                                    <template scope="scope">
                                        <el-input size="mini" v-model="scope.row.spec_number" placeholder="库存"></el-input>
                                    </template>
                                </el-table-column>

                                <el-table-column prop="is_on_sale" label="是否上架" width="200">
                                    <template scope="scope">
                                        <el-switch active-text="上架" inactive-text="下架" active-value="1" inactive-value="0" v-model="scope.row.is_on_sale"></el-switch>
                                    </template>
                                </el-table-column>

                                <el-table-column label="操作" width="70">
                                    <template scope="scope">
                                        <el-button
                                                size="mini"
                                                type="danger"
                                                icon="el-icon-delete" circle
                                                @click="specDelete(scope.$index, scope.row)">
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-button class="marginTop20" type="primary" @click="addSpecData">新增型号</el-button>
                        </div>
                    </el-form-item>

                    <el-form-item label="商品排序" prop="sort_order">
                        <el-input type="number" v-model="infoForm.sort_order"></el-input>
                        <div class="form-tip">排序: 数字越高，展示要越靠前！</div>
                    </el-form-item>
                    
                    <!-- <el-form-item label="商品成本价" prop="cost_price">
                        <el-input type="number" v-model="infoForm.cost_price"></el-input>
                        <div class="form-tip">单位：元</div>
                    </el-form-item> -->

                    
                    <el-form-item label="选择快递模板">
                        <el-select v-model="kdValue" placeholder="请选择快递" @change="kdChange">
                            <el-option
                                    v-for="item in kdOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="商品详情" prop="goods_desc">
                        <div class="edit_container">
                            <quill-editor v-model="infoForm.goods_desc"
                                          ref="myTextEditor"
                                          class="editer"
                                          :options="editorOption"
                                          @blur="onEditorBlur($event)"
                                          @ready="onEditorReady($event)">
                            </quill-editor>
                        </div>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="onSubmitInfo">确定保存</el-button>
                        <el-button @click="goBackPage">返回列表</el-button>
                        <el-button type="danger" class="float-right" @click="onCopyGood">复制商品</el-button>
                        <!--<el-button @click="testCallBack">回调</el-button>-->
                        <!--<el-button @click="getImgUrl">测试</el-button>-->
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <el-dialog v-model="dialogVisible" size="tiny">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>
    import api from '@/config/api';
    import $ from 'jquery'
    import {quillEditor} from 'vue-quill-editor'
    import ElForm from "../../../node_modules/element-ui/packages/form/src/form.vue";
    import ElFormItem from "../../../node_modules/element-ui/packages/form/src/form-item.vue"; //调用富文本编辑器
    import draggable from "vuedraggable";

    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{'header': 1}, {'header': 2}],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{'script': 'sub'}, {'script': 'super'}],
        [{'indent': '-1'}, {'indent': '+1'}],
        [{'direction': 'rtl'}],
        [{'size': ['small', false, 'large', 'huge']}],
        [{'header': [1, 2, 3, 4, 5, 6, false]}],
        [{'font': []}],
        [{'color': []}, {'background': []}],
        [{'align': []}],
        ['clean'],
        ['link', 'image', 'video']
    ]
    export default {
        data() {
            return {
                root: '',
                qiniuZone:'',
                picData: {
                    token: ''
                },
                url: '',
                kdOptions: [],
                kdValue: '',
                categorys:[],
                detail_list: [],
                dialogImageUrl: '',
                dialogVisible: false,
                options: [],
                cateOptions: [],
                uploaderHeader: {
                    'LuoShao-Token': localStorage.getItem('token') || '',
                },
                editorOption: {
                    modules: {
                        toolbar: {
                            container: toolbarOptions,  // 工具栏
                            handlers: {
                                'image': function (value) {
                                    if (value) {
                                        document.querySelector('.avatar-uploader input').click()
                                    } else {
                                        this.quill.format('image', false);
                                    }
                                }
                            }
                        },
                        syntax: {
                            highlight: text => hljs.highlightAuto(text).value
                        }
                    }
                },
                category: [],
                infoForm: {
                    name: "",
                    list_pic_url: '',
                    goods_brief: '',
                    goods_desc: '',
                    is_on_sale: 1,
                    goods_number:'',
                    keywords:[],
                    is_new: false,
                    play_times:1,
                    player_max_no:1,
                    player_min_no:1,
                    player_age:2,
                    cost_price:'',
                    retail_price:'',
                    sort_order:1
                    // is_index: false,
                },
                specData: [{
                    goods_id:'',//商品id
                    spec_sn:'',//商品sku
                    spec_number:'',//该规范的商品数量
                    retail_price:'',//零售价格
                    cost: '',//成本价格
                    spec_weight:'',//商品重量
                    spec_name: '',//商品名
                    is_on_sale: 1,//是否上架,
                    pic_url:''
                }],
                infoRules: {
                    name: [
                        {required: true, message: '请输入名称', trigger: 'blur'},
                    ],
                    goods_brief: [
                        {required: true, message: '请输入简介', trigger: 'blur'},
                    ],
                    list_pic_url: [
                        {required: true, message: '请选择商品图片', trigger: 'blur'},
                    ],
                    goods_number: [
                        {required: true, message: '请输入商品库存', trigger: 'blur'},
                    ],
                    play_times: [
                        {required: true, message: '请填写游戏时长', trigger: 'blur'},
                    ],
                    playerno: [
                        {required: true, message: '请填写游戏人数', trigger: 'blur'},
                    ],
                    player_age: [
                        {required: true, message: '请填写体验游戏的人群年龄', trigger: 'blur'},
                    ],
                    retail_price: [
                        {required: true, message: '请填写商品零售价', trigger: 'blur'},
                    ],
                    cost_price: [
                        {required: true, message: '请填写商品成本价', trigger: 'blur'},
                    ],
                    player_min_no: [
                        {required: true, message: '请填写最小体验人数', trigger: 'blur'},
                    ],
                    
                },
                gallery_list: [],
                gallery_urls: [],
                visible: false,
                hasPost: 0,
            }
        },
        methods: {

            beforeRemove(file, fileList) {
                return this.$confirm(`确定移除 ${ file.name }？`);
            },
            addTag() {
                
                if(!this.infoForm.keywords)
                {
                    this.infoForm["keywords"] = [];
                }
                console.log(this.infoForm);
				this.infoForm.keywords.push('关键字');
			},
			showCloseBtn(index){
				this.$refs[`closeI${index}`][0].style.display = 'block'; //显示叉叉
			},
			hideCloseBtn(index){
				this.$refs[`closeI${index}`][0].style.display = 'none';  //叉叉消失
			},
			checkTag(index){ //判断tag有无内容
				let text = this.$refs[`newTag${index}`][0].innerText;
                console.log("index test is " + text);
				if(text.length==0){
					this.infoForm.keywords.pop();
				}else{
					this.infoForm.keywords[index] = text;
				}
			},
			removeTag(index){  //删除叉叉
				this.infoForm.keywords.splice(index,1);
			},
            checkSkuOnly(index,row){
                console.log(index);
                console.log(row);
                if(row.spec_sn == ''){
                    this.$message({
                        type: 'error',
                        message: '规格编号不能为空'
                    })
                    return false;
                }
                return true;
            },
            hasErrorAct(err) {
                console.log(err);
            },
            getSpecData() {
                let goods_id = this.infoForm._id;
                var that = this;
                this.axios.get('goods/getGoodsSpec',
                    {
                        params:{
                            goods_id:goods_id
                        }
                    }
                    ).then((response) => {
                    if (response.data.code > 0) {
                        that.specData = response.data.data;
                        for(var i = 0;i !=  this.specData.length;i++)
                        {
                            that.specData[i].is_on_sale = that.specData[i].is_on_sale ? "1" : "0";
                        }
                        console.log(that.specData);
                    }
                })
            },
            addSpecData() {
                let ele = {
                    goods_id:'',//商品id
                    spec_sn:'',//商品sku
                    spec_number:'',//该规范的商品数量
                    retail_price:'',//零售价格
                    cost: '',//成本价格
                    spec_weight:'',//商品重量
                    spec_name: '',//商品名
                    is_on_sale: 1,//是否上架,
                    pic_url:''
                }
                this.specData.push(ele)
            },
            specDelete(index, row) {
                var specDataItem = this.specData[index];

                var that = this;

                this.$confirm('确定要删除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			    }).then(() => {
                    if(!!specDataItem&& !!specDataItem._id)
                    {
                        this.axios.post('goods/deleteSpec',
                        {
                            id:specDataItem._id
                        }).then((response) => {
                            console.log("specDelete结果出来了！！！");
                            console.log(response);
                            if (response.data.code> 0) {
                                that.$message({
                                    type: 'success',
                                    message: '删除成功'
                                });
                                that.specData.splice(index, 1);

                            } else {
                                that.$message({
                                    type: 'error',
                                    message: '删除失败'
                                });
                            }
                        })
                    }
                    else
                    {
                        that.specData.splice(index, 1);
                    }
			    });
            },
            getQiniuToken() {
                let that = this
                this.axios.get('qiniu/getQiniuToken').then((response) => {
                    let resInfo = response.data.data;
                    //console.log(resInfo.token);
                    that.picData.token = resInfo.token;
                    that.url = resInfo.url;
                })
            },
            goodsGalleryEdit() {
                this.$router.push({name: 'goodsgalleryedit', query: {id: this.infoForm.id}})
            },
            getImgUrl() {
                let str = this.infoForm.goods_desc;
                //匹配图片（g表示匹配所有结果i表示区分大小写）
                let imgReg = /<img [^>]*src=['"]([^'"]+)[^>]*>/gi;
                //匹配src属性
                let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;
                let arr = str.match(imgReg);
                if (arr == null) {
                    return false;
                }
                let data = [];

                for (let i = 0; i < arr.length; i++) {
                    let src = arr[i].match(srcReg);
                    let jsonData = {};
                    jsonData.url = src[1];
                    data[i] = jsonData;
                }
                this.detail_list = data;

            },
            submitUpload() {
                this.$refs.upload.submit();
            },
            delePicList() {
                // 不删除服务器上的图片，上传新的自动替换旧的
                this.infoForm.list_pic_url = null;
            },
            handlePreview(file) {
                console.log(file);
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            galleryBefore() {
                //this.gallery.goods_id = this.infoForm.id;
                this.getQiniuToken();//重点，这个地方不能去掉，否则将不能上传多个图片
            },
            galleryRemove(file, fileList) {
                var fileurl = file.url;
                var findex = -1;
                for(var i=0;i!=this.gallery_urls.length;i++)
                {
                    if(this.gallery_urls[i].url == fileurl){
                        findex = i;
                    }
                }

                if(findex>=0)
                {
                    this.gallery_urls.splice(findex,1);
                }

            },
            deleImg(data,index){
                this.gallery_urls.splice(index,1)
            },
            dragend()
            {
                
            },
            handleUploadGallerySuccess(res) {
                let url = this.url;
                
                if (res.key != '') {
                    let urlData = url + res.key;
                    let info = {
                        url: urlData,
                        name:urlData
                    }
                  
                    this.gallery_urls.push(info);
                    console.log( "gallery_urls ");
                    console.log(this.gallery_urls);
                }

            },
            galleryPreview(file) {
                console.log(file);
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            kdChange(kdValue) {
                console.log(kdValue);
                this.infoForm.freight_template_id = kdValue;
            },
            timeChange(val) {
                console.log(val);
                // this.infoForm.freight_template_id = kdValue;
            },
            onEditorReady(editor) {
                console.log('editor ready!', editor)
            },
            onEditorFocus(editor) {
                console.log('editor focus!', editor)
            },
            onEditorBlur(editor) {
                console.log('editor blur!', editor);
                console.log(this.infoForm.goods_desc);
            },

            beforeUpload() {
                // 显示loading动画
                this.getQiniuToken();
                this.quillUpdateImg = true
            },
            uploadError() {
                // loading动画消失
                this.quillUpdateImg = false
                this.$message.error('图片插入失败')
            },
            goBackPage() {
                this.$router.go(-1);
            },
            //富文本插入网络图片
            onLinkImageUrl() {
                var imageurl = document.querySelector('.url-image-fuzhu input').value
                let quill = this.$refs.myTextEditor.quill
                let length = quill.getSelection().index;
                quill.insertEmbed(length, 'image', imageurl)
                quill.setSelection(length + 1)
            },
            onSubmitInfo() {
                this.$refs['infoForm'].validate((valid) => {
                    if (valid) {
                        if (this.infoForm.index_pic_position > 0) {
                            if (this.infoForm.index_pic_url == '' || this.infoForm.index_pic_url == null) {
                                this.$message({
                                    type: 'error',
                                    message: '请上传特色图片或选择无'
                                })
                                return false;
                            }
                        }
                        var binfo ={
                            name:this.infoForm.name,
                            categorys:this.categorys,
                            goods_number:this.infoForm.goods_number,//商品数量
                            sell_volume:0,//销售数量
                            is_on_sale:this.infoForm.is_on_sale,//是否售卖
                            retail_price:this.infoForm.retail_price,//零售价格
                            cost_price: this.infoForm.cost_price,//成本价格
                            keywords:this.infoForm.keywords,
                            goods_brief:this.infoForm.goods_brief,//商品简介
                            goods_desc: this.infoForm.goods_desc,//商品描述
                            sort_order:this.infoForm.sort_order,//商品排序
                            play_times:this.infoForm.play_times,
                            player_max_no:this.infoForm.player_max_no,
                            player_min_no:this.infoForm.player_min_no,
                            player_age:this.infoForm.player_age,
                            goods_weight:this.infoForm.goods_weight,
                            freight_template_id:this.infoForm.freight_template_id,
                    //        is_new:true,//是否是新品
                            goods_unit:this.infoForm.goods_unit,//商品单位
                            special_feature:this.infoForm.special_feature,//特殊卖点
                          //  https_pic_url:baseinfo.https_pic_url,//轮播图
                            list_pic_url: this.infoForm.list_pic_url,//商品缩略图
                            gallery_urls:this.gallery_urls
                        }

                        // console.log("baseinfo is ");
                        // console.log(binfo);

                        // console.log("specData is ");
                        // console.log(this.specData);
                        
                        if(!!this.infoForm._id)
                        {
                            this.axios.post('goods/updateGoods',
                            {
                                baseinfo: binfo,
                                specData: this.specData,
                                id:this.infoForm._id
                            }).then((response) => {
                                console.log("保存结果出来了！！！");
                                console.log(response);
                            if (response.data.code> 0) {
                                this.$message({
                                    type: 'success',
                                    message: '更新成功'
                                });
                                this.$router.go(-1);
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '更新失败'
                                })
                            }
                        })
                        }
                        else
                        {
                            this.axios.post('goods/addGoods',
                            {
                                baseinfo: binfo,
                                specData: this.specData
                            }).then((response) => {
                                console.log("保存结果出来了！！！");
                                console.log(response);
                            if (response.data.code> 0) {
                                this.$message({
                                    type: 'success',
                                    message: '保存成功'
                                });
                                this.$router.go(-1);
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '保存失败'
                                })
                            }
                        })
                        }
                        
                    } else {
                        this.$message.error('请填写完整');
                        return false;
                    }
                });
            },
            handleUploadListSuccess(res) {
                let url = this.url;
                this.infoForm.list_pic_url = url + res.key;
                this.infoForm.https_pic_url = url + res.key;
                console.log("图片已经回来了！！！");
                console.log( this.infoForm.https_pic_url);
                /*
                this.axios.post('goods/uploadHttpsImage', {url:this.infoForm.list_pic_url}).then((response) => {
                    let lastUrl = response.data.data;

                    console.log(lastUrl);
                    this.infoForm.https_pic_url = lastUrl;
                })
                */
            },
            picUrlRemove(file, fileList) {
                this.infoForm.list_pic_url = null;
            },
            handleUploadIndexPicSuccess(res) {
                let url = this.url;
                console.log(url + res.key);
                this.infoForm.index_pic_url = url + res.key;
            },
            handleUploadDetailSuccess(res) {
                let url = this.url;
                let data = url + res.key;
                let quill = this.$refs.myTextEditor.quill
                // 如果上传成功
                // 获取光标所在位置
                let length = quill.getSelection().index;
                // 插入图片  res.info为服务器返回的图片地址
                quill.insertEmbed(length, 'image', data)
                // 调整光标到最后
                quill.setSelection(length + 1)

                // this.$message.error('图片插入失败')
                // loading动画消失
                this.quillUpdateImg = false
            },
            
            handleUploadImageSuccess(res, file) {
                if (res.code > 0) {
                    switch (res.data.name) {
                        //商品图片
                        case 'goods_pic':
                            this.infoForm.list_pic_url = res.data.fileUrl;
                            break;
                        case 'goods_detail_pic':
                            // res为图片服务器返回的数据
                            // 获取富文本组件实例
                            let quill = this.$refs.myTextEditor.quill
                            // 如果上传成功
                            // 获取光标所在位置
                            let length = quill.getSelection().index;
                            // 插入图片  res.info为服务器返回的图片地址
                            quill.insertEmbed(length, 'image', res.data.fileUrl)
                            // 调整光标到最后
                            quill.setSelection(length + 1)
                            // this.$message.error('图片插入失败')
                            // loading动画消失
                            this.quillUpdateImg = false
                            break;
                    }
                }
            },
            getInfo() {
                if (this.infoForm.id <= 0) {
                    return false
                }
                //加载商品详情
                let that = this
                this.axios.get('goods/getGoodsInfo', {
                    params: {
                        id: that.infoForm.id
                    }
                }).then((response) => {
                    let resInfo = response.data.data;
                    let goodsInfo = resInfo.info;
                    //  goodsInfo.is_index = goodsInfo.is_index ? true : false;
                    goodsInfo.is_new = goodsInfo.is_new ? true : false;
                    goodsInfo.is_on_sale = goodsInfo.is_on_sale ? "1" : "0";
                    that.infoForm = goodsInfo;
                    that.kdValue = goodsInfo.freight_template_id;
                    that.categorys = resInfo.category_id;
                    that.getImgUrl();
                })
            },
            // 获取所有分类
            getAllCategory() {
                let that = this;
                this.axios.get('goods/getCategorysList', {
                    params: {}
                }).then((response) => {
                    that.cateOptions = response.data.data;
                })
            },
            getExpressData() {
                let that = this
                this.axios.get('goods/getExpressData', {
                    params: {}
                }).then((response) => {
                    let options = response.data.data;
                    that.kdOptions = options.kd;
                })
            },
            // summernote 上传图片，返回链接
            sendFile(file) {
            },
            // 初始化 summernote
            initSummerNote() {
                let that = this
                $('#summernote').summernote({
                    lang: 'zh-CN',
                    placeholder: '请输入商品描述',
                    height: 300,
                    minHeight: 300,
                    maxHeight: 400,
                    focus: true,
                    // toolbar:[
                    //   ['style',['bold','italic','clear']],
                    //   ['fontsize',['fontsize']],
                    //   ['para',['ul','ol','paragraph']],
                    //   ['insert',['picture','link']]
                    // ],
                    callbacks: {
                        onBlur: function (e) {
                            console.log(" on blur ");
                            console.log($('#summernote').summernote('code'));
                            that.infoForm.goods_desc = $('#summernote').summernote('code');
                            console.log("that.infoForm.goods_desc ");
                            console.log(that.infoForm.goods_desc );
                            // that.infoForm.goods_desc = $('#summernote').summernote('code');
                        },
                        onImageUpload: function (files) {
                            console.log("onImageUpLoad...");
                            that.sendFile(files[0]);
                        }
                    }
                }),
                    // console.error(that.infoForm.goods_desc);
                    $('#summernote').summernote('code', that.infoForm.goods_desc)
            },
            backTop() {
                  var doc= document.documentElement;
                $(doc).animate({
                scrollTop:0
                },500);
            },
        },
        components: {
            ElFormItem,
            ElForm,
            quillEditor,
            draggable
        },
        computed: {
            editor() {
                return this.$refs.myTextEditor.quillEditor
            },
            dragOptions() {
                    return {
                    animation: 200,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                };
            }
        },
        mounted() {
            var goodsInfo = this.$route.query.goodsinfo || {keywords:[]} ;
            
            if(!!goodsInfo._id)
            {
                this.infoForm = this.$route.query.goodsinfo || {keywords:[]} ;
                this.gallery_list = this.infoForm.gallery_urls;
                this.gallery_urls = this.infoForm.gallery_urls;
                this.kdValue = this.infoForm.freight_template_id;
                this.categorys = Array.isArray(this.infoForm.categorys)?this.infoForm.categorys:[];
                this.infoForm.is_on_sale = this.infoForm.is_on_sale?"1":"0";
            }
            
            console.log(  this.infoForm );
            //this.getInfo();
            this.getAllCategory();
            this.getExpressData();
            this.getQiniuToken();
            this.getSpecData();
            this.root = api.rootUrl;
            this.qiniuZone = api.qiniu;
        },
    }

</script>

<style scoped>
    /* .edit_container{ */
    /*.avatar-uploader .el-upload {*/
    /*display: none;*/
    /*}*/
    .divBox{
		/*flex-direction: row;*/
		float: left;
		position: relative;
	}

    .imgSty{
		margin-left: 3px;
	}

    .close-i{
		position: absolute;
		top:-8px;
		right: -9px;
		display: none;
		cursor: pointer;
		font-size: 18px;
		background-color: white;
		border-radius: 10px;
	}
	.newtag{
		position: relative;
		cursor: text;
		overflow: visible;
		display: inline-block;
		padding: 0;	
	}
    .addtag
    {
        margin-left: 10px;
    }
	.newtag-content{
		margin: 7px 15px; 
	}

    .block {
        margin-bottom: 10px;
        height:42px;
        display: flex;
        align-items: center;
        justify-content:space-between;
    }

    .video-wrap{
        width: 300px;
    }
    .dialog-header {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
    }

    .dialog-header .value {
        width: 150px;
        margin-right: 14px;
    }

    .input-wrap .el-input {
        width: 200px;
        float: left;
        margin: 0 20px 20px 0;
    }

    .input-wrap .el-input input {
        background-color: #fff !important;
        color: #233445 !important;
    }

    .specFormDialig .el-input {
        width: 150px;
        margin-right: 10px;
    }

    .el-select-class {
        width: 200px;
        margin-right: 20px;
    }

    .sepc-form {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
    }

    .spec-form-wrap {
        margin-top: 0 !important;
    }

    .add-spec {
        margin-top: 10px;
    }

    .spec-form-wrap .header {
        display: flex;
        justify-content: flex-start;
    }

    .spec-form-wrap .header .l {
        width: 200px;
        margin-right: 20px;
    }

    .spec-form-wrap .header .m {
        width: 200px;
        margin-right: 20px;
    }

    .spec-form-wrap .header .m {
        width: 200px;
        margin-right: 20px;
    }

    /*.sepc-form div{*/
    /*margin-left: 0;*/
    /*}*/

    .float-right {
        float: right;
    }

    .sepc-form .el-input {
        width: 200px;
        margin-right: 20px;
    }

    .marginTop20 {
        margin-top: 20px;
    }

    .checkbox-wrap .checkbox-list {
        float: left;
        margin-right: 20px;
    }

    .upload_ad{
        display: none;
    }
    .upload_ad .el-upload--picture-card {
        display: none;
    }

    .ql-container {
        min-height: 200px;
        max-height: 400px;
        overflow-y: auto;
    }

    .image-uploader-diy {
        /*height: 200px;*/
        position: relative;
    }

    /*.dele-list-pic {*/
        /*position: absolute;*/
        /*left: 380px;*/
        /*top: 0px;*/
    /*}*/

    .image-uploader-diy .el-upload {
        border: 1px solid #d9d9d9;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .image-uploader-diy .el-upload:hover {
        border-color: #20a0ff;
    }

    .image-uploader-diy .image-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 200px;
        height: 200px;
        line-height: 200px;
        text-align: center;
    }

    .image-uploader-diy .image-show {
        min-width: 105px;
        height: 105px;
        display: block;
    }

    .image-uploader-diy .new-image-uploader {
        font-size: 28px;
        color: #8c939d;
        width: 165px;
        height: 105px;
        line-height: 105px;
        text-align: center;
    }

    .image-uploader-diy .new-image-uploader .image-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 165px;
        height: 105px;
        line-height: 105px;
        text-align: center;
    }

    .image-uploader-diy .new-image-uploader .image-show {
        width: 165px;
        height: 105px;
        display: block;
    }

    .item-url-image-fuzhu .el-input {
        width: 260px;
    }

    .hidden {
        display: none;
    }

    .backToTop
    {
        position:fixed;
        right:80px;
        bottom: 300px;
        width:40px;
        height:40px;
        font-size: 30px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100;
    }
</style>
